
#home-container {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 2fr) minmax(0, 1fr);
    grid-template-rows: repeat(4, max-content);
    gap: 20px;
    min-height: 100vh;
    align-items: start;
    position: relative;
}

#checkout-button-container { 
    position: fixed; 
    bottom: 0%;
    left: 0%;
    width: 100%;
    padding: 20px; 
    display: flex;
    justify-content: center;
    z-index: 50001;
}

#edit-menu-small-container, .gallery-modal, .add-shape-modal {
    z-index: 50002;
}

.checkout-modal, .checkout-modal-backdrop {
    z-index: 100000;
}

@media (max-width: 1100px) {
    #home-container {
        grid-template-columns: minmax(0, 1fr);
        grid-template-rows: repeat(3, min-content);
    }
}