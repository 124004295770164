.menu-transparent {
    opacity: 0.7;
}

.face {
    position: relative;
    background-color: white;
    overflow: hidden;
    -webkit-user-select: none; 
    -ms-user-select: none;
    user-select: none;
}

.face-image {
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
}

.portrait {
    width: 300px;
    height: calc(300px * 1.588235);
    position: relative;
    overflow: hidden;
}

.landscape {
    height: 300px;
    width: calc(300px * 1.59);
    position: relative;
    overflow: hidden;
}

.lanyard-hole {
    background-color: rgba(0, 0, 0, 0.4);
    /* background-image: linear-gradient(to right, #a2ea4c, #07aa91, #a2ea4c, #07aa91); */
    /* backdrop-filter: blur(5px); */
    backdrop-filter: blur(5px) sepia(90%);

    border-radius: 8px;
    position: absolute;
    z-index: 20;
    margin: auto;

    width: 28%; 
    height: 3%;
    top: 6%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* //left: calc(50% - 28% / 2) */
}

.portrait > .lanyard-hole {
    width: 28%; 
    height: 3%;
}

.landscape > .lanyard-hole {
    display: none;
}

.badge-object-selectable:hover {
    cursor: move;
    filter: brightness(120%);
}

.badge-object-selectable:hover .resize-dot {
    visibility: visible !important;
    opacity: .35;
}

.face-shape {
    pointer-events: none;
}

.face-shape .shape,
.face-shape .rotate-box,
.face-shape .resize-dot {
    pointer-events: initial;
}

.face-image-img {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

@media (max-width: 600px) { /* Should match value in gallery */
    .landscape {
        height: 200px;
        width: calc(200px * 1.59)
    }
}
