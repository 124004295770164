.badge-type-container {
    display: grid;
    align-items: center;
}

.badge-type-container-professional {
    grid-template-columns: 1fr 3fr;
    
}

.badge-type-container-entertainment > :first-child {
    grid-row: 1;
    grid-column: 2;
}

#editor-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: center;
    justify-items: center;
    gap: 20px;
}

#landing-grabber-container {
    position: absolute;
    top: 0; 
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    overflow: hidden;
    padding: 20px;
}

#badge-stack {
    transform: scale(.7);
}

#badge-stack .lanyard-hole {
    display: none;
}

.info-container {
    display: grid;
    align-items: center;
}

.left-info-container {
    grid-template-columns: 3fr 2fr;
}

.right-info-container {
    grid-template-columns: 2fr 3fr;
}

.left-info-container > :last-child{
    grid-column: 1;
    grid-row: 1;
}

.left-info-container .info-container-image {
    transform: translate(50px, -50px);
}

.right-info-container .info-container-image {
    transform: translate(-50px, -50px);
}

.info-container-image {
   max-height: 85%;
}

#discounted-prices-container {
    display: grid;
    grid-template-columns: repeat(2, 500px);
    justify-content: center;
    gap: 50px;
}

#quick-features-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
}

.discounted-prices-image {
    height: 400px;
}

#links {
    display: flex;
    justify-content: center;
    gap: 150px;
}

@media (max-width: 1100px) {
    .badge-type-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
    }

    #editor-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        
    }

    #badge-stack {
        transform: scale(.3);
    }

    .info-container  {
        grid-template-columns: 1fr;
    }

    .info-container-image {
        transform: translate(0, 0) !important;
    }

    .left-info-container > :last-child{
        grid-row: 2;
    }

    #discounted-prices-container {
        grid-template-columns: 1fr;
    }

    .discounted-prices-image {
        height: 250px;
    }

    #quick-features-container {
        grid-template-columns: 1fr;
    }

    #links {
        gap: 50px;
    }
}


@keyframes forbyyou {
    0% {opacity: 0;}
    10% {opacity: .33;}
    20% {opacity: .66;}
    30% {opacity: 1;}
    70% {opacity: 1;}
    80% {opacity: .66;}
    90% {opacity: .33;}
    100% {opacity: 0;}
  }