.filters {
    display: grid;
    grid-template-columns: repeat(4, minmax(0px, 100px));
    gap: 10px;
    justify-content: center;
}

.badges-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 100px;
    align-items: center;
}

@media (max-width: 600px) {
    .filters {
        grid-template-columns: repeat(2, minmax(0, 100px));
    }

    .badges-grid {
        gap: 50px;
    }
}