.face-tabs {
    display: flex;
    justify-content: center;
}

#multi-face {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: center;
}

#badge-editor {
    display: grid;
    grid-template-columns:  minmax(0, 2fr) minmax(0, 3fr);
}

#edit-menu-small-container {
    display: none;
}

#edit-menu-large-container {
    height: 100%;
}

@media (max-width: 1100px) {
    #badge-editor {
        grid-template-columns: 1fr;
    }

    #edit-menu-small-container {
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        max-height: 250px;
        overflow: scroll;
    }

    #edit-menu-large-container {
        display: none;
    }
}