#badge-options-container {
    display: grid;
    grid-template-columns: 350px min-content auto auto;
    gap: 15px;
    align-items: center
}

@media (max-width: 1100px) {
    #badge-options-container {
        grid-template-columns: auto;
    }

    
}