@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* This is needed to fix problem with text being offset */
@layer base {
    img {
      @apply inline-block;
    }
}

#container {
    max-width: 2000px;
    margin: auto;
}

.d-small {
    display: none;
}

.pointer:hover {
    cursor: pointer;
}

.dropdown {
    max-height: 20vh;
    overflow: scroll;
}

.color-picker:hover {
    cursor: pointer;
}

@media (max-width: 1100px) {
    .d-big {
        display: none;
    }

    .d-small {
        display: block;
    }
}