#pricing {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 800px;
    margin: auto;
    gap: 50px;
}


@media (max-width: 1100px) {
    #pricing {
        grid-template-columns: 1fr;
    }
    
}